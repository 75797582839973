<ng-container class="register__form__wrapper">
  <section *ngIf="registerClicked && registerForm" class="register__form">
    <div class="lg:grid lg:grid-cols-24">
      <main
        class="flex items-center justify-center sm:px-12 lg:col-span-12 lg:px-16 lg:py-12 xl:col-span-24"
      >
        <div class="max-w-xl lg:max-w-3xl flex flex-col items-center justify-center">
          <h1 class="mb-12 text-2xl pr-6 font-bold text-white sm:text-3xl md:text-4xl"> Register </h1>
          
          <button class="btn btn-square" (click)="changeClickState()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>

          <form action="#" class="mt-8 grid grid-cols-12 gap-6" [formGroup]="registerForm">
  
            <div class="col-span-6 sm:col-span-3">
              <label
                for="FirstName"
                class="block text-sm font-medium text-white"
              >
                Username
              </label>

              <input
                type="text"
                id="FirstName"
                name="first_name"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm focus:outline-none ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="username"
              />
            </div>

            <div class="col-span-6">
              <label for="Email" class="block text-sm font-medium text-white">
                Email
              </label>

              <input
                type="email"
                id="Email"
                name="email"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="email"
                [ngClass]="{'invalid': registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)}"
              />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="Password"
                class="block text-sm font-medium text-white"
              >
                Password
              </label>

              <input
                type="password"
                id="Password"
                name="password"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="password"
              />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="PasswordConfirmation"
                class="block text-sm font-medium text-white"
              >
                Password Confirmation
              </label>

              <input
                type="password"
                id="PasswordConfirmation"
                name="password_confirmation"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="repeatPassword"
              />
            </div>

            <p class="text-error"> {{ statusMessage }}</p>

            <div class="col-span-6 sm:flex flex-col sm:items-center sm:gap-4">
              <button [ngClass]=" { 'disabled': this.registerForm.invalid }"
                class="inline-block shrink-0 rounded-md border border-orange-500 bg-orange-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-orange-500 focus:outline-none  "
             (click)="register()">
                Create an account
              </button>

              <p class="mt-4 text-sm text-white-500 sm:mt-0">
                Already have an account?
                <a href="#" class="text-orange-700 underline" (click)="changeModalState()">Log in</a>.
              </p>
            </div>
          </form>
        </div>
      </main>
    </div>
  </section>

  <section *ngIf="loginClicked && loginForm" class="register__form">
    <div class="lg:grid lg:grid-cols-24">
      <main
        class="flex items-center justify-center sm:px-12 lg:col-span-12 lg:px-16 lg:py-12 xl:col-span-24"
      >
        <div class="max-w-xl lg:max-w-3xl flex flex-col items-center justify-center">
          <h1 class="mb-12 text-2xl pr-6 font-bold text-white sm:text-3xl md:text-4xl" > Login </h1>
          
          <button class="btn btn-square" (click)="changeClickState()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>

          <form action="#" class="mt-8 grid grid-cols-12 gap-6" [formGroup]="loginForm">
            <div class="col-span-6 sm:col-span-3">
              <label
                for="FirstName"
                class="block text-sm font-medium text-white"
              >
                Username
              </label>

              <input
                type="text"
                id="FirstName"
                name="first_name"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="username"
              />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="Password"
                class="block text-sm font-medium text-white">
                Password
              </label>

              <input
                type="password"
                id="Password"
                name="password"
                class="mt-1 w-full rounded-md border-gray-200 bg-transparent text-sm text-white shadow-sm ring-0 focus:ring-0 focus:border-orange-500"
                formControlName="password"
              />
            </div>

            <p class="text-error"> {{ loginStatusMessage }}</p>
        
            <div class="col-span-6 sm:flex flex-col sm:items-center sm:gap-4">
              <button *ngIf="!this.isLoading" [ngClass]="{ 'disabled': this.loginForm.invalid }"
                class="login-button inline-block shrink-0 rounded-md border border-orange-500 bg-orange-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-orange-500 focus:outline-none  "
                (click)="login()">
                  Login
              </button>

              <span *ngIf="this.isLoading" class="loading loading-infinity loading-lg"></span>

              <p class="mt-4 text-sm text-white-500 sm:mt-0">
                Don't have an account?
                <a href="#" class="text-primary underline" (click)="changeModalState()">Register</a>.
              </p>

              <p (click)="this.closeChange.emit(true)" [routerLink]="['/forgot-password']" class="mt-4 text-sm text-white underline sm:mt-0">
                Forgot password?
              </p>
            </div>
          </form>
        </div>
      </main>
    </div>
  </section>

</ng-container>
