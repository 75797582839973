<ng-container *ngIf="serverStatus">
    <ng-container *ngIf="serverStatus === 200; then serverOK else serverMaintenance"></ng-container>
</ng-container>

<ng-template #serverOK>
    <div [class]="[ 'app-container' ]">
        <app-navbar></app-navbar>

        <div class="content-starter"></div>

        <router-outlet (activate)="isFooterToBeLoaded = true"></router-outlet>

        <app-footer class="relative z-1" *ngIf="isFooterToBeLoaded"></app-footer>

        <!-- <app-cookies-bar></app-cookies-bar> -->
    </div>
</ng-template>

<ng-template #serverMaintenance>
    <app-maintenance></app-maintenance>
</ng-template>
