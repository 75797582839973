export const endpoints = {
  common: {
    maintenance: 'common/maintenance',
  },
  auth: {
    register: 'auth/register',
    login: 'auth/login',
    forgotenPassword: 'auth/password/forgotten',
    resetPassword: 'auth/password/reset',
    refreshAccessToken: 'auth/refresh-access-token',
  },
  stats: {
    getStats: 'stats',
  },
  user: {
    getUpdate: 'user',
    download: 'user/download',
    clearKeys: 'user/clear-keys',
    suspend: 'user/suspend',
    changePassword: 'user/change-password',
    updateUser: 'user/update/:id',
  },
  bybit: {
    validate: 'bybit/validate',
    balance: 'bybit/balance',
  },
  cloudinary: {
    upload: 'cloudinary/upload',
  },
  tournaments: {
    createTournament: 'tournament/create',
    getAllTournaments: 'tournament/get',
    getTournamentByTitle: 'tournament/get-by-title/:title',
    pauseUnpauseTournament: 'tournament/pause-unpause',
    addUserToTournament: 'tournament/add',
    removeUserFromTournament: 'tournament/remove',
    fetchTournamentResult: 'tournament/:tournamentId/fetch',
    deleteTournament: 'tournament/delete',
  },
  discord: {
    authorize: 'discord/authorize'
  }
}
