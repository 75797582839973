export enum UserRole {
  Member,
  Moderator,
  Admin
}

export enum ApiKeyStatus {
  Active,
  Tournament,
  Disabled,
}

export enum UserRank {
  Basic,
  Holder,
  Ninja,
}

export interface UserStats {
  currentEloRank: number;
}

export interface tournamentPartial {
  status: string;
  title: string;
  _id: string;
}

export interface ApiKeys {
  accountName: string;
  status: ApiKeyStatus;
  uid: string;
  api_public: string;
  api_secret: string;
  parentUid: string;
}

export  interface User {
  id: string;
  role: UserRole;
  email: string;
  verified: boolean;
  discordId: string;
  password: string;
  username: string;
  suspended: boolean,
  createdAt: Date;
  rank: UserRank[];
  stats: UserStats;
  tournamentsId: tournamentPartial[];
  winrate: number;
  wins:  number;
  elo: number;
  apiKeys: ApiKeys[];
}
